import React, { useState, useEffect, useRef } from "react";

import {
    ChangePassword,
    FORGOT_PASSWORD,
    Password,
} from "../constant";

import { Popover, PopoverHeader, PopoverBody } from "reactstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import Swal from "sweetalert2";
import { useSelector } from "react-redux";
import useAxiosTemplates from "../customHooks/useAxiosTemplates";
import { Alert } from "reactstrap";
import { AlertTriangle, PlusCircle, RefreshCw } from "react-feather";
import ConfigDB from "../data/customizer/config";
import formValidation from "../customHooks/useValidation";
import axios from "axios";

const ForgotPasswords = () => {
    let navigate = useNavigate();
    const dispatch = useDispatch();

    // form controls
    const [isValidated, setIsValidated] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [isF2Validated, setIsF2Validated] = useState(false);

    const [showAlert, setShowAlert] = useState(false);

    // helper containers
    const enteredPassword = useRef("");

    // stored data
    const loggedUser = useSelector((content) => content.UserReducer);
    const { customer_id } = useParams();

    // custom hooks
    const sendRequest = useAxiosTemplates();

    const [clientCode, setClientCode] = useState('');

    useEffect(() => {
        document.title = "Edge ERP";
        if (localStorage.getItem("token") && loggedUser.orgId !== 0) {

        }
        if (!localStorage.getItem("store")) {
            dispatch({
                type: "SIGNOUT_USER",
            });

        }
    });

    const submitNewPassword = (evt) => {
        setIsF2Validated(true);
        evt.preventDefault();
        if (evt.target.checkValidity()) {
            const formData = new FormData(evt.target);
            let user = {
                clientId: localStorage.getItem("temp_clientid"),
                orgId: localStorage.getItem("temp_orgid"),
                loggedUserId: localStorage.getItem("temp_userid"),
                userId: localStorage.getItem("temp_userid"),
                isPasswordChanged: true,
                password: formData.get("fm_2_confirm_password"),
            };
            const reqInstance = axios.create({
                baseURL: ConfigDB.data.backendServerUrl,
                headers: { Authorization: `Bearer ${localStorage.getItem("temp_token")}` },
            });
            reqInstance
                .post("/saveUser", user)
                .then((response) => {
                    const res = response.data;
                    if (res) {
                        if (res.isSuccess) {

                            Swal.fire({
                                icon: "success",
                                title: "Request Successful",
                                text: "User Passsword has successfully updated.",
                                showConfirmButton: false,
                                timer: 5000,
                            });
                        }
                        console.log(res.username);
                        if (localStorage.getItem("temp_username") === "saproAdmin") {
                            navigate("/login");
                        } else {
                            navigate("/userLogin");
                        }

                    }
                   
                })
        }
    };



    return (
        <div>
            <div className="page-wrapper">
                <div className="container-fluid p-0">
                    {/* <!-- login page start--> */}
                    <div className="authentication-main">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="auth-innerright">
                                    <div className="authentication-box">
                                        <div className="text-center">
                                            <span className="fs-1 fw-bold text-danger">
                                                THE EDGE
                                            </span>
                                            <br />
                                            <span className="fs-4 text-warning">E R P</span>
                                        </div>

                                        <div className="card mt-4">
                                            <div className="card-body">
                                                <div className="text-center">
                                                    <h4>{FORGOT_PASSWORD}</h4>
                                                    <h6>{"Please Enter your New Password and Confirm it"} </h6>
                                                </div>

                                                {/* {!loggedUser.userId ? ( */}
                                                <form
                                                    onSubmit={(evt) => submitNewPassword(evt)}
                                                    className={`form-1 ${isValidated ? "was-validated" : ""
                                                        }`}
                                                    noValidate
                                                >


                                                    <div className="form-group mb-4">
                                                        <label htmlFor="id_input_fm_1_password">
                                                            New Password
                                                        </label>
                                                        <input
                                                            ref={enteredPassword}
                                                            id="id_input_fm_2_password"
                                                            name="fm_2_password"
                                                            type="password"
                                                            maxLength={45}
                                                            minLength={8}
                                                            className="form-control"
                                                            placeholder="Enter New Password"
                                                            onChange={(evt) =>
                                                                formValidation({
                                                                    event: evt,
                                                                    validatePassword: true,
                                                                })
                                                            }
                                                            required
                                                        />
                                                        <small className="invalid-feedback position-absolute">
                                                            Please enter a New Password
                                                        </small>
                                                        <small className="txt-danger position-absolute d-none input-validation"></small>
                                                    </div>

                                                    <div className="form-group mb-4">

                                                        <label htmlFor="id_input_fm_2_confirm_password">
                                                            Confirm New Password
                                                        </label>
                                                        <input
                                                            id="id_input_fm_2_confirm_password"
                                                            name="fm_2_confirm_password"
                                                            type="password"
                                                            maxLength={45}
                                                            className="form-control"
                                                            placeholder="Reenter new Password"
                                                            onChange={(evt) =>
                                                                formValidation({
                                                                    event: evt,
                                                                    confirmPassword: true,
                                                                    enteredPassword: enteredPassword.current.value,
                                                                })
                                                            }
                                                            required
                                                        />
                                                        <small className="invalid-feedback position-absolute">
                                                            Please ReEnter new Password
                                                        </small>
                                                        <small className="txt-danger position-absolute d-none input-validation"></small>
                                                    </div>
                                                    <div className="" style={{color:"#EA2027"}}>
                                                        {/* <ol className=" d-flex flex-column"> */}
                                                        {/* <li>Minimum character length of password is 8.</li>
                                                                    <li>
                                                                        Passowrd must consist of at least a single character
                                                                        from Uppercase Letters, Lowercase Letters, Digits
                                                                        and Special Characters (!@#$%^&*-_+).
                                                                    </li> */}
                                                        <span>Minimum character length of password is 8. Passowrd must consist of at least a single character
                                                            from Uppercase Letters, Lowercase Letters, Digits
                                                            and Special Characters (!@#$%^&*-_+).</span>
                                                        {/* </ol> */}
                                                    </div>



                                                    <div className="form-group form-row mt-3 mb-0 d-grid">
                                                        <button className="btn btn-primary" type="submit">
                                                            {ChangePassword}
                                                        </button>
                                                    </div>
                                                </form>
                                                {/* ) : null} */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <!-- login page end--> */}
                </div>
            </div>
        </div>
    );
};

export default ForgotPasswords;
