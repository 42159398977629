import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import useAccess from "../../../../customHooks/useAccess";
import useAxiosTemplates from "../../../../customHooks/useAxiosTemplates";
import { useSelector } from "react-redux";
import { Clock } from "react-feather";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import DetailRowLayout from "../../../common/DetailRowLayout";
import NoDetailsFound from "../../../../assets/images/not-details-found.png";
import { LoadStaticURL } from "../../../../data/util/LoadStaticURL";
import { ConvertMillisToDate } from "../../../../data/util/DateUtil";

const Education = ({ b_partner_id = 0, client_id = 0, org_id = 0 }) => {
  const LOADING = 0;
  const LOADED = 1;

  const EDITFUNCTION = "EditCustomer";
  const DEACTIVATEFUNCTION = "DeactivateCustomer";

  let navigate = useNavigate();
  const { customer_id } = useParams();

  // stored data
  const loggedUser = useSelector((content) => content.UserReducer);

  // form controls
  const [isLoaded, setIsLoaded] = useState(LOADING);
  const [customerData, setCustomerData] = useState(undefined);

  // custom hooks
  const [checkIsAccessible] = useAccess(loggedUser, "Customer");
  const sendRequest = useAxiosTemplates();

  useEffect(() => {
    const reqBody = {
      clientId: client_id,
      orgId: org_id,
      userId: loggedUser.userId,
      bpartnerId: b_partner_id,
    };
    new Promise((resolve) => {
      const result = sendRequest({
        url: "/customer/viewProfileEducationTab",
        data: reqBody,
      });
      resolve(result);
    }).then((response_data) => {
      setCustomerData(response_data);
      setIsLoaded(LOADED);
    });
  }, []);

  return (
    <div className="card-modified-body bg-white pt-0 mb-4">
      <div>{isLoaded === LOADING ? <Skeleton className="w-25" /> : null}</div>
      <div>
        {isLoaded === LOADED ? (
          customerData.educationList.length > 0 ? (
            customerData.educationList.map((education_record_item, index) => (
              <div className="mb-4" key={index}>
                <div
                  className="d-flex align-items-center mb-2"
                  style={{ minWidth: "max-content" }}
                >
                  <Clock height="12px" className="text-secondary"></Clock>
                  <div className="text-secondary">
                    {education_record_item.updated ===
                      education_record_item.created
                      ? "Created "
                      : "Last Modified "}
                    on {ConvertMillisToDate(education_record_item.updated)} By{" "}
                    {education_record_item.updatedBy}{" "}
                  </div>
                </div>
                <div key={index}>
                  <DetailRowLayout
                    display={isLoaded === LOADED}
                    labels={["Level", "Achievement",
                      "Stream","Qualified Year","Remark"
                    ]}
                    data={
                      isLoaded === LOADED
                        ? [
                          education_record_item.levelName,
                          education_record_item.achievementName,
                          education_record_item.fieldName,
                          education_record_item.qualifiedYear,
                          education_record_item.remark
                        ]
                        : null
                    }
                    active={education_record_item.isActive}
                  />
                  {/* <DetailRowLayout
                    display={isLoaded === LOADED}
                    labels={["Subject", "Grade", "Qualified Year"]}
                    data={
                      isLoaded === LOADED
                        ? [
                          education_record_item.subjectName,
                          education_record_item.gradeName,
                          education_record_item.qualifiedYear,
                        ]
                        : null
                    }
                    active={education_record_item.isActive}
                  /> */}
                  {education_record_item.attachmentList?.length > 0 ? (
                    <DetailRowLayout
                      display={isLoaded === LOADED}
                      attachment_row={true}
                      data={education_record_item.attachmentList}
                      active={education_record_item.isActive}
                    />
                  ) : null}
                  {/* <DetailRowLayout
                    display={isLoaded === LOADED}
                    labels={["Remark"]}
                    data={
                      isLoaded === LOADED
                        ? [education_record_item.remark]
                        : null
                    }
                    active={education_record_item.isActive}
                    lastRow={true}
                  /> */}
                </div>
              </div>
            ))
          ) : (
            <div className="d-flex flex-column align-items-center">
              <img src={LoadStaticURL(NoDetailsFound)} alt="" width={"200px"} />
              <p className="ml-2 fs-5 fw-bold text-warning">
                No Details Inserted.
              </p>
            </div>
          )
        ) : (
          // Loading Skeleton
          <>
            <DetailRowLayout
              display={isLoaded === LOADED}
              labels={["Level", "Achievement", "Stream","Qualified Year","Remark"]}
              data={[]}
            />
            {/* <DetailRowLayout
              display={isLoaded === LOADED}
              labels={["Subject", "Grade", "Qualified Year"]}
              data={[]}
            />
            <DetailRowLayout
              display={isLoaded === LOADED}
              labels={["Remark"]}
              data={[]}
            /> */}
          </>
        )}
        <div className="d-flex justify-content-end mt-4 mx-1">
          {checkIsAccessible(EDITFUNCTION) && b_partner_id !== 0 && isLoaded ? (
            <button
              onClick={() =>
                navigate(
                  `/customer/view/${customer_id}/update/save_customer_tab_8/`
                )
              }
              className="btn btn-primary col-12 col-md-2"
            >
              {customerData.educationList.length > 0 ? "Update" : "Insert"}
            </button>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default Education;
