import React, { useState, useEffect } from "react";
import { HOME_PAGE_RT, LOGIN, RESET_PASSWORD_CONFIRM, UserConfirmation, UserEmail } from "../constant";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import useAxiosTemplates from "../customHooks/useAxiosTemplates";
import Swal from "sweetalert2";
import { Alert } from "reactstrap";
import { AlertTriangle, PlusCircle, RefreshCw } from "react-feather";
import formValidation from "../customHooks/useValidation";

const ResetPasswordConfirm = () => {
    let navigate = useNavigate();

    // stored data
    const loggedUser = useSelector((content) => content.UserReducer);

    // custom hooks
    const sendRequest = useAxiosTemplates();

    // form controls
    const [isValidated, setIsValidated] = useState(false);

    const returnToSignIn = (evt) => {
        evt.preventDefault();
        if (localStorage.getItem("temp_username") === "saproAdmin") {
            navigate("/login");
        } else {
            navigate("/userLogin");
        }
    };


    return (
        <div>
            <div className="page-wrapper">
                <div className="container-fluid p-0">
                    {/* <!-- login page start--> */}
                    <div className="authentication-main">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="auth-innerright">
                                    <div className="authentication-box">
                                        <div className="text-center">
                                            <span className="fs-1 fw-bold text-danger">
                                                THE EDGE
                                            </span>
                                            <br />
                                            <span className="fs-4 text-warning">E R P</span>
                                        </div>
                                        <div className="card mt-4">
                                            <div className="card-body">
                                                <div className="text-center">
                                                    <h4>{RESET_PASSWORD_CONFIRM}</h4>
                                                    <h6 className="reset-password-confirm mt-4">{"Check your email for a link to reset your password. If it doesn’t appear within a few minutes, check your spam folder."} </h6>
                                                </div>

                                                <div className="form-group form-row mt-3 mb-0 d-grid">
                                                    <button className="btn btn-outline-primary" type="button" onClick={(evt) => returnToSignIn(evt)}>
                                                        Return to sign in
                                                    </button>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <!-- login page end--> */}
                </div>
            </div>
        </div>
    );
};

export default ResetPasswordConfirm;
