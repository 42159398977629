export class ConfigDB {
  static data = {
    developmentMode: true,
    // isPassport: true,
    backendServerUrl:
      // "http://192.168.1.101:8080/",

      // "https://6d55-2402-d000-8104-839-b487-5692-1eba-714e.ngrok-free.app",

      "https://theedgebe.saprosolutions.com/EdgeErpBackEnd/",

    // "https://theedgebe.saprosolutions.com/",

    // fileServerUrl: "https://erp.deshakthee.com/image",  

    // fileServerUrl: "http://192.168.1.127/Image",
    // documentServerUrl: "http://192.168.1.127/Document",
    // videoServerUrl: "http://192.168.1.127/Video",

    // videoServerUrl: "https://erp.deshakthee.com/video",

    fileServerUrl: "https://theedgebe.saprosolutions.com/EdgeErpBackEnd/Image",
    documentServerUrl: "https://theedgebe.saprosolutions.com/EdgeErpBackEnd/Document",
    videoServerUrl: "https://theedgebe.saprosolutions.com/EdgeErpBackEnd/Video",

    // videoServerUrl: "http://theedge.saprosolutions.com/Video",
    // documentServerUrl: "http://theedge.saprosolutions.com/Document", 

    hosted_url:
      // "http://localhost/DeshaktheeERP/#",
      // "http://localhost:3000/#",
      "https://theedge.saprosolutions.com/#",

    token_refresh_time_interval_in_millis: 1000 * 60 * 30, //1800000, //30m
    session_expiration_time: 2400000, //40m
    check_user_active_timer_interval: 5000, //5sec
    visiting_customer_check_timer_interval: 1000 * 60 * 10, // 10 min
    max_file_input_size_in_bytes: 5242880, //5MB
    max_video_input_size_in_bytes: 104857600, //50MB
    settings: {
      layout_type: "ltr",
      sidebar: { wrapper: "default", bodyWrapper: "default" },
      sidebar_setting: "default-sidebar",
      sidebar_backround: "dark-sidebar",
      hash_router: true,
      apache_folder_name: "TheEdgeERP",
    },
    color: {
      layout_version: "light",
      color: "color-1",
      primary_color: "#1B998B",
      secondary_color: "#2e9de4",
      mix_layout: "default",
    },
    router_animation: "fadeIn",
  };
}

export default ConfigDB;
