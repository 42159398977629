import React, { useState, useEffect } from "react";
// import {
//   Login,
//   USER_LOGIN,
//   UserName,
//   Password,
//   ClientCode,
//   RememberMe,
//   HOME_PAGE_RT,
//   LOG_W_ORG_PAGE_RT,
// } from "../constant";

import {
  Login,
  USER_LOGIN,
  UserName,
  Password,
  ClientCode,
  RememberMe,
  UserEmail,
} from "../constant";

import { Popover, PopoverHeader, PopoverBody } from "reactstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import Swal from "sweetalert2";
import { Eye, EyeOff } from "react-feather";
import { useSelector } from "react-redux";
import useAxiosTemplates from "../customHooks/useAxiosTemplates";
import { Alert } from "reactstrap";
import { AlertTriangle, PlusCircle, RefreshCw } from "react-feather";
import ConfigDB from "../data/customizer/config";
import formValidation from "../customHooks/useValidation";
import image from "../assets/images/favicon_old_2.png";

const UserLogin = () => {
  let navigate = useNavigate();
  const dispatch = useDispatch();

  // form controls
  const [isValidated, setIsValidated] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const [showAlert, setShowAlert] = useState(false);
  const [showAlertWithValue, setShowAlertWithValue] = useState(false);
  const [showAlertWithoutValue, setShowAlertWithoutValue] = useState(false);

  const [loginCount, setLoginCount] = useState(0);
  const [logUserId, setLogUserId] = useState(0);

  // stored data
  const loggedUser = useSelector((content) => content.UserReducer);
  const { customer_id } = useParams();
  // custom hooks
  const sendRequest = useAxiosTemplates();

  const [clientCode, setClientCode] = useState('');

  // Get references to the username input field and the "Forgot Password" link
  const usernameInput = document.getElementById('id_input_fm_1_username');
  const forgotPasswordLink = document.getElementById('forgotPassword');

  useEffect(() => {
    document.title = "The Edge ERP";
    if (localStorage.getItem("token") && loggedUser.orgId !== 0) {

      // if (customer_id) {
      //   setShowAlert(true);

      // }

    }
    if (!localStorage.getItem("store")) {
      dispatch({
        type: "SIGNOUT_USER",
      });

    }
  });

  useEffect(() => {
    if (showPassword) {
      document.querySelector("input[name='fm_1_password']").type = "text";
    } else {
      document.querySelector("input[name='fm_1_password']").type = "password";
    }
  }, [showPassword]);

  const loginAuth = (evt) => {
    setIsValidated(true);
    evt.preventDefault();


    if (evt.target.checkValidity()) {
      const formData = new FormData(evt.target);
      const loginDetails = {
        username: formData.get("fm_1_email"),
        // useremail: formData.get("fm_1_email"),
        password: formData.get("fm_1_password"),
        clientCode: "EdgeWeb",
      };

      let responseData = new Promise((resolve) => {
        const result = sendRequest({
          url: "/checkLogin",
          data: loginDetails,
          check_token: false,
        });
        resolve(result);
      });
      responseData.then((response_data) => {
        if (response_data) {
          if (response_data.isLoginSuccess) {
            localStorage.setItem("lastTokenRefreshedAt", new Date());

            console.log(response_data.username);
            if (loggedUser.orgId === 0) {
              if (response_data.activeOrgRoleList.length > 1) {
                dispatch({
                  type: "AUTHENTICATE_USER",
                  payload: {
                    clientId: 0,
                    orgId: 0,
                    userId: response_data.userId,
                    username: response_data.username,
                    bpartnerId: response_data.bpartnerId,
                    employeeName: response_data.employeeName,
                    userRoleId: response_data.roleId,
                    roleName: response_data.roleName,
                    profilePictureDocumentPath:
                      response_data.profilePictureDocumentPath,
                    activeClientList: response_data.activeClientList,
                    activeOrgRoleList: response_data.activeOrgRoleList,
                  },
                });
                localStorage.setItem("token", response_data.token);
                localStorage.setItem("store", response_data.username);
                // sign in log
                console.log(
                  `Logged Succefully at ${localStorage.getItem(
                    "lastTokenRefreshedAt"
                  )}`
                );
                console.log(response_data);
                // navigate(LOG_W_ORG_PAGE_RT);
              } else {
                if (response_data.activeOrgRoleList.length) {
                  localStorage.setItem("token", response_data.token);
                  // sign in log
                  console.log(
                    `Logged Succefully at ${localStorage.getItem(
                      "lastTokenRefreshedAt"
                    )}`
                  );
                  console.log(response_data);
                  const reqBody = {
                    orgId: response_data.activeOrgRoleList[0].orgId,
                    roleId: response_data.roleId,
                  };
                  let nestedResponseData = new Promise((resolve) => {
                    const result = sendRequest({
                      url: "/getUserRoleLine",
                      data: reqBody,
                    });
                    resolve(result);
                  });
                  nestedResponseData.then((nested_response_data) => {
                    dispatch({
                      type: "STORE_ROLE_LIST",
                      payload: {
                        roleList: nested_response_data,
                      },
                    });
                    dispatch({
                      type: "AUTHENTICATE_USER",
                      payload: {
                        clientId: response_data.activeClientList[0].clientId,
                        orgId: response_data.activeOrgRoleList[0].orgId,
                        userId: response_data.userId,
                        username: response_data.username,
                        bpartnerId: response_data.bpartnerId,
                        employeeName: response_data.employeeName,
                        userRoleId: response_data.roleId,
                        roleName: response_data.roleName,
                        profilePictureDocumentPath:
                          response_data.profilePictureDocumentPath,
                        activeClientList: response_data.activeClientList,
                        activeOrgRoleList: response_data.activeOrgRoleList,
                      },
                    });
                    // **HOST
                    // navigate("/");
                    // navigate(`/customer/view/${customer_id}/user_account/`);

                    // window.open(
                    //   `${ConfigDB.data.hosted_url}/customer/view/${customer_id}/user_account/`,
                    //   "_blank"
                    // );
                  });
                } else {
                  Swal.fire({
                    icon: "error",
                    title: "Sorry",
                    text: "You don't have access to any Organization.",
                    showConfirmButton: false,
                    timer: 5000,
                  });
                }
              }
            }
            else {
              localStorage.setItem("token", response_data.token);

              const reqBody2 = {
                clientId: response_data.activeClientList[0].clientId,
                orgId: response_data.activeOrgRoleList[0].orgId,
                userId: response_data.userId,
                bpartnerId: response_data.bpartnerId,
              };

              setLogUserId(response_data.userId);
              console.log(response_data.userId);

              let nestedResponseData0 = new Promise((resolve) => {
                const result = sendRequest({
                  url: "/customer/viewProfilePersonalTab",
                  data: reqBody2,
                });
                resolve(result);
              });
              nestedResponseData0.then((nested_response_data) => {
                dispatch({
                  type: "STORE_CUSTOMER",
                  payload: {
                    bpartnerId: nested_response_data.bpartnerId,
                    clientId: response_data.activeClientList[0].clientId,
                    orgId: response_data.activeOrgRoleList[0].orgId,
                    searchKey: nested_response_data.customerId,
                  },
                });

                const dpListRequestBody = {  userId: response_data.userId, };
                let responseData = new Promise((resolve) => {
                    const result = sendRequest({
                        url: "/loginCount",
                        data: dpListRequestBody,
                    });
                    resolve(result);
                });
                responseData.then((response_data) => {
        
                    console.log(dpListRequestBody);
                    console.log(response_data);
                    
                    setLoginCount(response_data.count)
                    if(parseInt(response_data.count)<3){
                  
                      console.log("user account")
                      navigate(`/customer/view/${nested_response_data.customerId}/user_account/`);
                    }else{
                      console.log("user dash")
                      navigate(`/customer/view/${nested_response_data.customerId}/`);
                    }
                
                  });
        

                console.log(response_data.count)

                // navigate(`/customer/view/${nested_response_data.customerId}/user_account/`);


              });

              

            }
          } else {
            Swal.fire({
              icon: "error",
              title: "Login Failed",
              text: "Wrong Email or Password",
              showConfirmButton: false,
              timer: 5000,
            });
          }
        }
      });
    }

  };


  

//   useEffect(() => {
//     console.log("check")
//     // if (loggedUser.bpartnerId > 0) {

//     console.log(logUserId);

//         const dpListRequestBody = {  userId: logUserId, };
//         let responseData = new Promise((resolve) => {
//             const result = sendRequest({
//                 url: "/loginCount",
//                 data: dpListRequestBody,
//             });
//             resolve(result);
//         });
//         responseData.then((response_data) => {

//             console.log(response_data.count);
//             setLoginCount(response_data.count)
//         });

//     // }
//     // setIsLoaded(LOADED);
// }, []);

  const togglePasswordDisplay = () => {
    setShowPassword((prevState) => !prevState);
  };


  const handleForgotPasswordClick = (evt) => {
    evt.preventDefault();
    
    navigate("/verifyEmail");
  };


  return (
    <div>
      <div className="page-wrapper">
        <div className="container-fluid p-0">
          {/* <!-- login page start--> */}
          <div className="authentication-main">
            <div className="row">
              <div className="col-md-12">
                <div className="auth-innerright">
                  <div className="authentication-box">
                    {/* <div className="media align-items-center">
                    <img
                      className="align-self-center mx-auto img-50 rounded-circle blur-up lazyloaded"

                      src={

                        image
                      }
                      alt="header-user"
                      
                      />
                    </div> */}
                    <div className="text-center">

                     
                      <span className="fs-1 fw-bold text-danger">
                        THE EDGE
                      </span>
                      <br />
                      <span className="fs-4 text-warning">E R P</span>
                    </div>

                    <div className="card mt-4">
                      <div className="card-body">
                        <div className="text-center">
                          <h4>{USER_LOGIN}</h4>
                          <h6>{"Enter your user name and password"} </h6>
                        </div>


                        <form
                          onSubmit={(evt) => loginAuth(evt)}
                          className={`form-1 ${isValidated ? "was-validated" : ""
                            }`}
                          noValidate
                        >
                          {/* <div className="form-group mb-4">
                            <label
                              htmlFor="id_input_fm_1_username"
                              className="col-form-label pt-0"
                            >
                              {UserName}
                            </label>
                            <input
                              id="id_input_fm_1_username"
                              name="fm_1_username"
                              className="form-control"
                              placeholder={UserName}
                              type="text"
                              required
                            />
                          </div> */}
                          <div className="form-group mb-4">
                            <label
                              htmlFor="id_input_fm_1_email"
                              className="col-form-label pt-0"
                            >
                              {UserName}
                            </label>
                            <input
                              id="id_input_fm_1_email"
                              name="fm_1_email"
                              className="form-control"
                              placeholder={UserName}
                              type="email"
                              onChange={(evt) =>
                                formValidation({
                                  event: evt,
                                  validateEmail: true,
                                })
                              }
                              required
                            />
                          </div>
                          <div className="form-group mb-4">
                            <label
                              htmlFor="id_input_fm_1_password"
                              className="col-form-label"
                            >
                              {Password}
                            </label>
                            <div className="input-group mb-3">
                              <input
                                id="id_input_fm_1_password"
                                name="fm_1_password"
                                className="form-control"
                                placeholder={Password}
                                type="password"
                                required
                                aria-describedby="basic-addon2"
                              />
                              <span
                                className="input-group-text bg-white"
                                id="basic-addon2"
                                onClick={togglePasswordDisplay}
                              >
                                {showPassword ? (
                                  <EyeOff height={"16px"} width={"16px"} />
                                ) : (
                                  <Eye height={"16px"} width={"16px"} />
                                )}
                              </span>
                            </div>
                          </div>
                          {/* <div className="form-group mb-4">
                            <label
                              htmlFor="id_input_fm_1_client_code"
                              className="col-form-label"
                            >
                              {ClientCode}
                            </label>
                            <input
                              id="id_input_fm_1_client_code"
                              name="fm_1_client_code"
                              className="form-control"
                              placeholder={ClientCode}
                              value="EdgeWeb"
                              autoComplete="off"
                              type="text"
                              required
                            />
                          </div> */}

                          <div className="form-group mb-4 d-flex justify-content-between align-items-center">
                            {/* <div className="checkbox p-0 col-6">
                              <input
                                id="id_input_fm_1_remember_me"
                                name="fm_1_remember_me"
                                type="checkbox"
                              />
                              <label htmlFor="id_input_fm_1_remember_me">
                                {RememberMe}
                              </label>
                            </div> */}

                            <div className="">
                              <Link
                                to="#"
                                className="link-info"
                                id="forgotPassword" onClick={(evt) => handleForgotPasswordClick(evt)}>
                                Forgot Password ?
                              </Link>
                            </div>


                          </div>

                          <div className="form-group form-row mt-3 mb-0 d-grid">
                            <button className="btn btn-primary" type="submit">
                              {Login}
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <!-- login page end--> */}
        </div>
      </div>
    </div>
  );
};

export default UserLogin;
