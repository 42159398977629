import React, { Fragment, useState, useEffect, useCallback } from "react";
import { useSelector } from "react-redux";
import logo_compact from "../../../assets/images/logo/compact-logo.png";
import UserPanel from "./userPanel";
import {
  MENUITEMS,
  CUSTOMERMENUITEMS,
} from "../../../components/common/sidebar-component/menu";
import { Link, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import configDB from "../../../data/customizer/config";
import useAccess from "../../../customHooks/useAccess";
import { CUSTOMER_DETAILS, CUSTOMER_HISTORY, CV_GENERATOR, DASHBOARD, EDIT_CUSTOMER, HOME_PAGE_RT, INTERVIEW_VIDEO, USER_ACCOUNT } from "../../../constant";

const Sidebar = (props) => {
  const { customer_id } = useParams();
  const [margin, setMargin] = useState(0);
  const [width, setWidth] = useState(0);
  const [hideLeftArrowRTL, setHideLeftArrowRTL] = useState(true);
  const [hideRightArrowRTL, setHideRightArrowRTL] = useState(true);
  const [hideRightArrow, setHideRightArrow] = useState(true);
  const [hideLeftArrow, setHideLeftArrow] = useState(true);
  const [mainmenu, setMainMenu] = useState({
    mainmenu: customer_id !== undefined ? CUSTOMERMENUITEMS : MENUITEMS,
  });
  const { t } = useTranslation();
  const wrapper = configDB.data.settings.sidebar.wrapper;
  const layout = useSelector((content) => content.Customizer.layout);
  const loggedUser = useSelector((content) => content.UserReducer);
  const customer = useSelector((content) => content.CustomerReducer);
  const accessFunction = useAccess(loggedUser);
  const checkIsInterfaceAccessible = accessFunction[2];
  const checkIsFunctioneAccessible = accessFunction[0];

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    handleResize();

    var currentUrl = window.location.pathname;

    mainmenu.mainmenu.filter((items) => {
      //   if (items.path === currentUrl) setNavActive(items);
      if (!items.children) return false;
      items.children.filter((subItems) => {
        // if (subItems.path === currentUrl) setNavActive(subItems);
        if (!subItems.children) return false;
        subItems.children.filter((subSubItems) => {
          if (subSubItems.path === currentUrl) {
            setNavActive(subSubItems);
            return true;
          } else {
            return false;
          }
        });
        return subItems;
      });
      return items;
    });
    const timeout = setTimeout(() => {
      const elmnt = document.getElementById("myDIV");
      const menuWidth = elmnt.offsetWidth;
      if (menuWidth > window.innerWidth) {
        setHideRightArrow(false);
        setHideLeftArrowRTL(false);
      } else {
        setHideRightArrow(true);
        setHideLeftArrowRTL(true);
      }
    }, 500);

    return () => {
      window.removeEventListener("resize", handleResize);
      clearTimeout(timeout);
    };
  }, []);

  const checkMenuItem = useCallback(
    (menu_items) => {
      let menuItems = menu_items.map((item) => {
        // this should also include as an interface
        if (customer_id === undefined) {
          if (item.title.toLocaleLowerCase() === "dashboard") return item;
          if (item.title.toLocaleLowerCase() === "customer") {
            const resultInterfaces = item.children?.map((child_item) => {
              if (child_item.type === "link") {
                switch (child_item.title.toLocaleLowerCase()) {
                  case "new customer":
                    if (
                      checkIsFunctioneAccessible("InsertCustomer", "Customer")
                    )
                      return child_item;
                    break;
                  case "view customer":
                    if (checkIsFunctioneAccessible("ViewCustomer", "Customer"))
                      return child_item;
                    break;

                  default:
                    return false;
                }
              }
            });
            const filteredInterfaces = resultInterfaces.filter(
              (sub_item) => sub_item !== undefined
            );
            if (filteredInterfaces.length > 0) {
              const newItem = { ...item, children: filteredInterfaces };
              return newItem;
            }
          }
        } else {
          switch (item.title.toLocaleLowerCase()) {
            case DASHBOARD:
              return {
                ...item,
                path: `/customer/view/${customer_id}/`,
                title: loggedUser.roleName === "NormalUser" ? "My Dashboard" : "Dashboard",
              };
            case CUSTOMER_DETAILS:
              return {
                ...item,
                path: `/customer/view/${customer_id}/details/`,
                title: loggedUser.roleName === "NormalUser" ? "My Details" : "Customer Details",
              };
            case EDIT_CUSTOMER:
              if (checkIsFunctioneAccessible("EditCustomer", "Customer"))
                return {
                  ...item,
                  path: `/customer/view/${customer_id}/update/save_customer_tab_1`,
                  title: loggedUser.roleName === "NormalUser" ? "Edit My Details" : "Edit Customer",
                };
              break;
            case CUSTOMER_HISTORY:
              return {
                ...item,
                path: `/customer/view/${customer_id}/history/`,
              };
            case INTERVIEW_VIDEO:
              if (loggedUser.roleName === "EdgeAdmin") {
                return {
                  ...item,
                  path: `/customer/view/${customer_id}/interview_video/`,
                };
              }
              break;
            case CV_GENERATOR:
              return {
                ...item,
                path: `/customer/view/${customer_id}/cv/`,
              };
            case USER_ACCOUNT:
              return {
                ...item,
                path: `/customer/view/${customer_id}/user_account/`,
                title: loggedUser.roleName === "NormalUser" ? "My Account" : "User Account",
              };
            default:
              return item;
          }
        }
        if (item.type === "sub") {
          const resultInterfaces = item.children?.map((child_item) => {
            if (child_item.type === "link") {
              if (
                checkIsInterfaceAccessible(
                  child_item.title.replace(/ /g, "").toLocaleLowerCase()
                )
              )
                return child_item;
            } else if (child_item.type === "sub") {
              const filteredSubInterfaces = child_item.children?.filter(
                (sub_child_item) =>
                  checkIsInterfaceAccessible(
                    sub_child_item.title.replace(/ /g, "").toLocaleLowerCase()
                  )
              );
              if (filteredSubInterfaces.length > 0) {
                const newItem = {
                  ...child_item,
                  children: filteredSubInterfaces,
                };
                // console.log(newItem);
                return newItem;
              }
            }
          });
          const filteredInterfaces = resultInterfaces.filter(
            (sub_item) => sub_item !== undefined
          );
          if (filteredInterfaces.length > 0) {
            const newItem = { ...item, children: filteredInterfaces };
            // console.log(newItem);
            return newItem;
          }
        }
      });
      setMainMenu({
        mainmenu: menu_items.filter((item) => item !== undefined),
      });
      menuItems = menuItems.filter((item) => item !== undefined);
      // console.log(menuItems);
      return menuItems;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [checkIsInterfaceAccessible]
  );

  useEffect(() => {
    setMainMenu({ mainmenu: checkMenuItem(mainmenu.mainmenu) });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkMenuItem]);

  const handleResize = () => {
    setWidth(window.innerWidth - 310);
  };

  const setNavActive = (item) => {
    mainmenu.mainmenu.filter((menuItem) => {
      if (menuItem !== item) menuItem.active = false;
      if (menuItem.children && menuItem.children.includes(item))
        menuItem.active = true;
      if (menuItem.children) {
        menuItem.children.filter((submenuItems) => {
          if (submenuItems.children && submenuItems.children.includes(item)) {
            menuItem.active = true;
            submenuItems.active = true;
            return true;
          } else {
            return false;
          }
        });
      }
      return menuItem;
    });
    item.active = !item.active;
    setMainMenu({ mainmenu: mainmenu.mainmenu });
  };

  // Click Toggle menu
  const toggletNavActive = (item) => {
    if (!item.active) {
      mainmenu.mainmenu.forEach((a) => {
        if (mainmenu.mainmenu.includes(item)) a.active = false;
        if (!a.children) return false;
        a.children.forEach((b) => {
          if (a.children.includes(item)) {
            b.active = false;
          }
          if (!b.children) return false;
          b.children.forEach((c) => {
            if (b.children.includes(item)) {
              c.active = false;
            }
          });
        });
      });
    }
    item.active = !item.active;
    const newMenuList = mainmenu.mainmenu.map((listItem) =>
      listItem.title === item.title ? item : listItem
    );
    setMainMenu({ mainmenu: checkMenuItem(newMenuList) });
  };

  const scrollToRight = () => {
    const elmnt = document.getElementById("myDIV");
    const menuWidth = elmnt.offsetWidth;
    const temp = menuWidth + margin;
    if (temp < menuWidth) {
      setMargin(-temp);
      setHideRightArrow(true);
    } else {
      setMargin((margin) => (margin += -width));
      setHideLeftArrow(false);
    }
  };

  const scrollToLeft = () => {
    // If Margin is reach between screen resolution
    if (margin >= -width) {
      setMargin(0);
      setHideLeftArrow(true);
    } else {
      setMargin((margin) => (margin += width));
      setHideRightArrow(false);
    }
  };

  const scrollToLeftRTL = () => {
    if (margin <= -width) {
      setMargin((margin) => (margin += -width));
      setHideLeftArrowRTL(true);
    } else {
      setMargin((margin) => (margin += -width));
      setHideRightArrowRTL(false);
    }
  };

  const scrollToRightRTL = () => {
    const temp = width + margin;
    // Checking condition for remaing margin
    if (temp === 0) {
      setMargin(temp);
      setHideRightArrowRTL(true);
    } else {
      setMargin((margin) => (margin += width));
      setHideRightArrowRTL(false);
      setHideLeftArrowRTL(false);
    }
  };

  return (
    <Fragment>
      <div className="page-sidebar">
        <div className="main-header-left d-none d-lg-block">
          <div className="logo-wrapper">
            <div className="compactLogo">
              <div className="d-flex align-items-center">
                <img
                  className="blur-up lazyloaded"
                  width="50px"
                  src={logo_compact}
                  alt=""
                />
                <Link to={HOME_PAGE_RT}>
                  <div className="" style={{ marginLeft: "16px" }}>
                    <span className="fs-6 fw-bold text-white">THE EDGE</span>
                    <br />
                    <span className="fs-6 text-white">ERP SYSTEM</span>
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="sidebar custom-scrollbar">
          <UserPanel />
          <ul
            className="sidebar-menu"
            id="myDIV"
            style={
              wrapper === "horizontal_sidebar"
                ? layout === "rtl"
                  ? { marginRight: margin + "px" }
                  : { marginLeft: margin + "px" }
                : { margin: "0px" }
            }
          >
            <li
              className={`left-arrow ${layout === "rtl"
                ? hideLeftArrowRTL
                  ? "d-none"
                  : ""
                : hideLeftArrow
                  ? "d-none"
                  : ""
                }`}
              onClick={
                wrapper === "horizontal_sidebar" && layout === "rtl"
                  ? scrollToLeftRTL
                  : scrollToLeft
              }
            >
              <i className="fa fa-angle-left"></i>
            </li>
            {mainmenu?.mainmenu?.map((menuItem, i) => (
              <li className={`${menuItem.active ? "active" : ""}`} key={i}>
                {menuItem.sidebartitle ? (
                  <div className="sidebar-title">{menuItem.sidebartitle}</div>
                ) : (
                  ""
                )}
                {menuItem.type === "sub" ? (
                  // eslint-disable-next-line jsx-a11y/anchor-is-valid
                  <a
                    className="sidebar-header"
                    onClick={() => toggletNavActive(menuItem)}
                    style={{ cursor: "pointer" }}
                  >
                    <menuItem.icon />
                    <span>{t(menuItem.title)}</span>
                    <i className="fa fa-angle-right pull-right"></i>
                  </a>
                ) : (
                  ""
                )}
                {menuItem.type === "link" ? (
                  <Link
                    to={`${menuItem.path}`}
                    className={`sidebar-header ${menuItem.active ? "active" : ""
                      }`}
                    onClick={() => toggletNavActive(menuItem)}
                  >
                    <menuItem.icon />
                    <span>{t(menuItem.title)}</span>
                    {menuItem.children ? (
                      <i className="fa fa-angle-right pull-right"></i>
                    ) : (
                      ""
                    )}
                  </Link>
                ) : (
                  ""
                )}
                {menuItem.children ? (
                  <ul
                    className={`sidebar-submenu ${menuItem.active ? "menu-open" : ""
                      }`}
                    style={
                      menuItem.active
                        ? { opacity: 1, transition: "opacity 500ms ease-in" }
                        : {}
                    }
                  >
                    {menuItem.children.map((childrenItem, index) => (
                      <li
                        key={index}
                        className={
                          childrenItem.children
                            ? childrenItem.active
                              ? "active"
                              : ""
                            : ""
                        }
                      >
                        {childrenItem.type === "sub" ? (
                          // eslint-disable-next-line jsx-a11y/anchor-is-valid
                          <a
                            onClick={() => toggletNavActive(childrenItem)}
                            style={{ cursor: "pointer" }}
                          >
                            <i className="fa fa-circle"></i>
                            {t(childrenItem.title)}{" "}
                            <i className="fa fa-angle-right pull-right"></i>
                          </a>
                        ) : (
                          ""
                        )}

                        {childrenItem.type === "link" ? (
                          <Link
                            to={`${childrenItem.path}`}
                            className={childrenItem.active ? "active" : ""}
                            onClick={() => {
                              toggletNavActive(childrenItem);
                              if (childrenItem.title === "New Customer" && customer.bpartnerId > 0) {
                                // window.location.reload();
                                // console.log("Relaod Page");
                                // console.log(customer.bpartnerId);
                              }
                            }}
                          >
                            <i className="fa fa-circle"></i>
                            {t(childrenItem.title)}{" "}
                          </Link>
                        ) : (
                          ""
                        )}
                        {childrenItem.children ? (
                          <ul
                            className={`sidebar-submenu ${childrenItem.active ? "menu-open" : "active"
                              }`}
                          >
                            {childrenItem.children.map(
                              (childrenSubItem, key) => (
                                <li
                                  className={
                                    childrenSubItem.active ? "active" : ""
                                  }
                                  key={key}
                                >
                                  {childrenSubItem.type === "link" ? (
                                    <Link
                                      // to={`${process.env.PUBLIC_URL}${childrenSubItem.path}`}
                                      to={`${childrenSubItem.path}`}
                                      className={
                                        childrenSubItem.active ? "active" : ""
                                      }
                                      onClick={() =>
                                        toggletNavActive(childrenSubItem)
                                      }
                                    >
                                      <i className="fa fa-circle"></i>
                                      {t(childrenSubItem.title)}
                                    </Link>
                                  ) : (
                                    ""
                                  )}
                                </li>
                              )
                            )}
                          </ul>
                        ) : (
                          ""
                        )}
                      </li>
                    ))}
                  </ul>
                ) : (
                  ""
                )}
              </li>
            ))}
            <li
              className={`right-arrow ${layout === "rtl"
                ? hideRightArrowRTL
                  ? "d-none"
                  : ""
                : hideRightArrow
                  ? "d-none"
                  : ""
                }`}
              onClick={
                wrapper === "horizontal_sidebar" && layout === "rtl"
                  ? scrollToRightRTL
                  : scrollToRight
              }
            >
              <i className="fa fa-angle-right"></i>
            </li>
          </ul>
        </div>
      </div>
    </Fragment>
  );
};

export default Sidebar;
