import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import useAccess from "../../../../../customHooks/useAccess";
import useAxiosTemplates from "../../../../../customHooks/useAxiosTemplates";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { Clock } from "react-feather";
import DetailRowLayout from "../../../../common/DetailRowLayout";
import NoDetailsFound from "../../../../../assets/images/not-details-found.png";
import { LoadStaticURL } from "../../../../../data/util/LoadStaticURL";
import { ConvertMillisToDate } from "../../../../../data/util/DateUtil";

const Address = ({ b_partner_id = 0, client_id = 0, org_id = 0 }) => {
  const LOADING = 0;
  const LOADED = 1;

  const EDITFUNCTION = "EditCustomer";
  const DEACTIVATEFUNCTION = "DeactivateCustomer";

  let navigate = useNavigate();
  const { customer_id } = useParams();

  // stored data
  const loggedUser = useSelector((content) => content.UserReducer);

  // form controls
  const [isLoaded, setIsLoaded] = useState(LOADING);
  const [customerData, setCustomerData] = useState(undefined);

  // custom hooks
  const [checkIsAccessible] = useAccess(loggedUser, "Customer");
  const sendRequest = useAxiosTemplates();

  useEffect(() => {
    const reqBody = {
      clientId: client_id,
      orgId: org_id,
      userId: loggedUser.userId,
      bpartnerId: b_partner_id,
    };
    new Promise((resolve) => {
      const result = sendRequest({
        url: "/customer/viewProfileAddressTab",
        data: reqBody,
      });
      resolve(result);
    }).then((response_data) => {
      setCustomerData(response_data);
      setIsLoaded(LOADED);
    });
  }, []);

  return (
    <div className="card-modified-body bg-white mb-4">
      <div>{isLoaded === LOADING ? <Skeleton className="w-25" /> : null}</div>
      <div>
        {isLoaded === LOADED ? (
          customerData.addressList.length > 0 ? (
            customerData.addressList.map((address_item, index) => (
              <div className="mb-4" key={index}>
                <div
                  className="d-flex align-items-center mb-2"
                  style={{ minWidth: "max-content" }}
                >
                  <Clock height="12px" className="text-secondary"></Clock>
                  <div className="text-secondary">
                    {address_item.updated === address_item.created
                      ? "Created "
                      : "Last Modified "}
                    on {ConvertMillisToDate(address_item.updated)} By{" "}
                    {address_item.updatedBy}{" "}
                  </div>
                </div>
                <div key={index}>
                  <DetailRowLayout
                    display={isLoaded === LOADED}
                    labels={[
                      // "District",
                      "Address Type",
                      "Country",
                      "State",
                      "City",
                      "Address",
                      "Remark"
                    ]}
                    data={
                      isLoaded === LOADED
                        ? [

                          address_item.customerAddressTypeName,
                          address_item.country,
                          address_item.state,
                          address_item.cityName,
                          address_item.address,
                          address_item.remark
                        ]
                        : null
                    }
                    active={address_item.isActive}
                  />
                  {/* <DetailRowLayout
                    display={isLoaded === LOADED}
                    labels={["Address",
                    //  "Default"
                    ]}
                    data={
                      isLoaded === LOADED
                        ? [
                            address_item.address,
                            // `${address_item.isDefault ? "Yes" : "No"}`,
                          ]
                        : null
                    }
                    active={address_item.isActive}
                  /> */}
                  {/* <DetailRowLayout
                    display={isLoaded === LOADED}
                    labels={["Remark"]}
                    data={isLoaded === LOADED ? [address_item.remark] : null}
                    active={address_item.isActive}
                    lastRow={true}
                  /> */}
                </div>
              </div>
            ))
          ) : (
            <div className="d-flex flex-column align-items-center">
              <img src={LoadStaticURL(NoDetailsFound)} alt="" width={"200px"} />
              <p className="ml-2 fs-5 fw-bold text-warning">
                No Details Inserted.
              </p>
            </div>
          )
        ) : (
          <>
            <DetailRowLayout
              display={isLoaded === LOADED}
              labels={[
                // "District",
                "Address Type",
                "Country",
                "State",
                "City",
                "Address",
                "Remark",
                "Any Comment"
              ]}
              data={[]}
            />
            {/* <DetailRowLayout
              display={isLoaded === LOADED}
              labels={["Address",
               "Default"
              ]}
              data={[]}
            />
            <DetailRowLayout
              display={isLoaded === LOADED}
              labels={["Any Comment"]}
              data={[]}
            /> */}
          </>
        )}
        <div className="d-flex justify-content-end mt-4 mx-1">
          {checkIsAccessible(EDITFUNCTION) && b_partner_id !== 0 && isLoaded ? (
            <button
              onClick={() =>
                navigate(
                  `/customer/view/${customer_id}/update/save_customer_tab_5/`
                )
              }
              className="btn btn-primary col-12 col-md-2"
            >
              {customerData.addressList.length > 0 ? "Update" : "Insert"}
            </button>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default Address;
