import React, { useState, useEffect } from "react";
import { RESET_PASSWORD, ResetEmail, UserEmail } from "../constant";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import useAxiosTemplates from "../customHooks/useAxiosTemplates";
import Swal from "sweetalert2";
import { Alert } from "reactstrap";
import { AlertTriangle, PlusCircle, RefreshCw } from "react-feather";
import formValidation from "../customHooks/useValidation";

const VerifyEmail = () => {
  const dispatch = useDispatch();
  let navigate = useNavigate();

  const [showAlert, setShowAlert] = useState(false);

  // stored data
  const loggedUser = useSelector((content) => content.UserReducer);

  // custom hooks
  const sendRequest = useAxiosTemplates();

  // form controls
  const [isValidated, setIsValidated] = useState(false);

  const loginAuth = (evt) => {
    setIsValidated(true);
    evt.preventDefault();
    if (evt.target.checkValidity()) {
      const formData = new FormData(evt.target);
      const emailDetails = {
        useremail: formData.get("fm_1_email"),
      };
      let responseData = new Promise((resolve) => {
        const result = sendRequest({
          url: "/getUserResetPasswordEmailConfirmation",
          data: emailDetails,
        });
        resolve(result);
      });
      responseData.then((reponse_data) => {
        if (reponse_data) {
          if (reponse_data.isSuccess) {
            Swal.fire({
              icon: "success",
              title: "Request Successful",
              showConfirmButton: false,
              timer: 5000,
            }).then((res) => {

              setShowAlert(true);
            });

            navigate("/resetPasswordConfirm");
          } else {
            Swal.fire({
              icon: "error",
              text: " Please enter your correct Email before proceeding with the password reset.",
              showConfirmButton: false,
              timer: 5000,
            });
          }

        }
        localStorage.setItem("temp_token", reponse_data.token);
        localStorage.setItem("temp_userid",reponse_data.userId);
        localStorage.setItem("temp_orgid", reponse_data.orgId);
        localStorage.setItem("temp_clientid",reponse_data.clientId);
        localStorage.setItem("temp_username",reponse_data.userName);
      });

    }
  };


  return (
    <div>
      <div className="page-wrapper">
        <div className="container-fluid p-0">
          {/* <!-- login page start--> */}
          <div className="authentication-main">
            <div className="row">
              <div className="col-md-12">
                <div className="auth-innerright">
                  <div className="authentication-box">
                    <div className="text-center">
                      <span className="fs-1 fw-bold text-danger">
                        THE EDGE
                      </span>
                      <br />
                      <span className="fs-4 text-warning">E R P</span>
                    </div>
                    <div className="card mt-4">
                      <div className="card-body">
                        <div className="text-center">
                          <h4 className="reset-passwword mt-0">{RESET_PASSWORD}</h4>
                          <h6 className="reset-description mt-4 txt-info">{"Enter your user account's verified email address and we will send you a password reset link."} </h6>
                        </div>



                        <form
                          onSubmit={(evt) => loginAuth(evt)}
                          className={`form-1 mt-4 ${isValidated ? "was-validated" : ""
                            }`}
                          noValidate
                        >

                          <div className="form-group mb-4">
                            <label
                              htmlFor="id_input_fm_1_email"
                              className="col-form-label pt-0"
                            >
                              {UserEmail}
                            </label>
                            <input
                              id="id_input_fm_1_email"
                              name="fm_1_email"
                              className="form-control"
                              placeholder={ResetEmail}
                              type="email"
                              onChange={(evt) =>
                                formValidation({
                                  event: evt,
                                  validateEmail: true,
                                })
                              }
                              required
                            />
                          </div>

                          {/* {showAlert && (
                            <Alert
                              className="alert-dismissible d-flex align-items-center"
                              color="warning"
                              isOpen={true}
                              toggle={() => setShowAlert(false)}
                            // toggle={setShowAlert(false)}
                            // isOpen={true}
                            >
                              <AlertTriangle />
                              <div className="ml-2" style={{marginLeft:20}}>
                                Please verify email address.
                              </div>
                            </Alert>
                          )} */}

                          <div className="form-group form-row mt-3 mb-0 d-grid">
                            <button className="btn btn-primary" type="submit">
                              Send password reset email
                            </button>
                          </div>



                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <!-- login page end--> */}
        </div>
      </div>
    </div>
  );
};

export default VerifyEmail;
