import React, { useRef, useState } from "react";
import { Trash } from "react-feather";
import Swal from "sweetalert2";
import ConfigDB from "../../data/customizer/config";
import imageOnError from "../../assets/images/default-image.jpg";
import { LoadStaticURL } from "../../data/util/LoadStaticURL";
import useAxiosTemplates from "../../customHooks/useAxiosTemplates";
import { useSelector } from "react-redux";
import videoIcon from "../../assets/images/videoIcon.png"

const SkillVideoUploader = ({
  label,
  attachment_list,
  preview_list,
  set_attachment_list,
  set_preview_list,
  max_file_limit,
  set_refresh_table,
}) => {
  // input field data
  const fileValidationMessage = useRef("");
  const fileComponent = useRef("");
  const sendRequest = useAxiosTemplates();
  const loggedUser = useSelector((content) => content.UserReducer);

  const [uploading, setUploading] = useState(0);

  const handleFileUpload = (event) => {
    let reader = new FileReader();
    reader.onprogress = function () {
      setUploading(1);
    };
    reader.onload = function () {
      console.log(reader.result);
      console.log(event.target.files[0].type);
      setUploading(0);
      console.log("Megabytes => ", event.target.files[0].size / 1024 ** 2);
      if (
        event.target.files[0].size <
          ConfigDB.data.max_video_input_size_in_bytes &&
        preview_list.length < max_file_limit
      ) {
        if (fileComponent.current.classList.contains("is-invalid"))
          fileComponent.current.classList.remove("is-invalid");
        if (
          preview_list.find((item) => item.source === reader.result) ===
          undefined
        ) {
          set_preview_list((prevList) => [
            ...prevList,
            { id: 0, type: event.target.files[0].type, source: reader.result },
          ]);
          set_attachment_list((prev_list_items) => [
            ...prev_list_items,
            {
              documentId: 0,
              isModified: true,
              attachmentExtension: `.${
                event.target.files[0].type.split("/")[1]
              }`,
              attachment: event.target.files[0], //multipart form data
              base64source: reader.result
            },
          ]);
        }
      } else {
        if (!fileComponent.current.classList.contains("invalid-feedback"))
          fileComponent.current.classList.add("is-invalid");
        fileValidationMessage.current.innerHTML =
          preview_list.length >= max_file_limit
            ? `Cannot insert more than ${max_file_limit} Files.`
            : `Please insert files less than ${
                ConfigDB.data.max_video_input_size_in_bytes / 1024 ** 2
              }MB`;
      }
    };
    reader.readAsDataURL(event.target.files[0]);
  };

  const handleRemove = (preview_item, index) => {
    setUploading(0);
    console.log(preview_item);
    let removingItem = undefined;
    if (fileComponent.current.classList.contains("is-invalid"))
      fileComponent.current.classList.remove("is-invalid");
    if (preview_item.id === 0) {
      removingItem = attachment_list.find(
        (item) => item.base64source === preview_item.source
      );
      console.log(attachment_list);
      if (removingItem !== undefined) {
        let tempAttachments = attachment_list;
        tempAttachments.splice(attachment_list.indexOf(removingItem), 1);
        let tempPreviews = preview_list;
        tempPreviews.splice(index, 1);
        set_attachment_list([...tempAttachments]);
        set_preview_list([...tempPreviews]);
      }
    } else {
      Swal.fire({
        icon: "warning",
        title: "Are you sure?",
        text: "This will permanently deletes this file from the server.",
        showDenyButton: true,
        confirmButtonText: "Delete",
        denyButtonText: "Cancel",
        denyButtonColor: "#2a3142",
      }).then((result) => {
        if (result.isConfirmed) {
          removingItem = attachment_list.find(
            (item) => item.videoId === preview_item.id
          );
          if (removingItem !== undefined) {
           
            const reqBody = {
                clientId: loggedUser.clientId,
                orgId: loggedUser.orgId,
                userId: loggedUser.userId,
                videoId: preview_item.id,
            };

            console.log(reqBody);
            let responseData = new Promise((resolve) => {
                sendRequest({
                    url: "deleteModifyVideo",
                    data: reqBody,
                    template: "CONTROL_DATA",
                });
                // respl(result);
                resolve(result);
            });
            responseData.then((response_data) => {
                if (response_data) {
                  Swal.fire({
                    icon: "success",
                    title: "Request Successful",
                    text: "Video has successfully deleted.",
                    showConfirmButton: false,
                    timer: 5000,
                  });
                  set_refresh_table(true);
                }
              });

            let tempAttachments = attachment_list;
            tempAttachments.splice(attachment_list.indexOf(removingItem), 1);
            let tempPreviews = preview_list;
            tempPreviews.splice(index, 1);
            set_attachment_list([...tempAttachments]);
            set_preview_list([...tempPreviews]);
          }
        }
      });
    }
  };

  return (
    <div className="form-group mb-4">
      <label>{label}</label>
      <div ref={fileComponent} className="border rounded p-4 form-control">
        {/* previewer */}
        <div className="form-row">
          {preview_list.map((listItem, index) => (
            <div
              className={`col-12 col-sm-5 m-2 py-2 d-flex align-items-center justify-content-between bg-light rounded`}
              key={index}
            >
              <video width="200px" alt="">
                <source
                  src={
                    listItem.id
                      ? `${ConfigDB.data.videoServerUrl}${listItem.source}`
                      : listItem.source
                  }
                  type="video/mp4"
                  onError={(event) => {
                    event.target.src = LoadStaticURL(videoIcon);
                    event.onerror = null;
                  }}
                />
              </video>
              <div className="btn btn-link text-danger">
                <Trash
                  height={"20px"}
                  onClick={() => handleRemove(listItem, index)}
                />
              </div>
            </div>
          ))}
        </div>
        {/* input handler */}
        <div className="form-row align-items-center">
          <div className="btn btn-outline-primary position-relative">
            Upload Video
            <input
              type="file"
              accept="video/*"
              className="form-control"
              onChange={(e) => handleFileUpload(e)}
            />
          </div>
          {uploading
            ? [...Array(5).keys()].map((item, index) => (
                <div
                  key={index}
                  className="spinner-grow txt-secondary mx-2"
                  style={{ width: "10px", height: "10px" }}
                  role="status"
                >
                  <span className="sr-only">Loading...</span>
                </div>
              ))
            : null}
        </div>
      </div>
      <div ref={fileValidationMessage} className="invalid-feedback"></div>
      <small className="" style={{color:"#EA2027"}}>
        <ul className="d-sm-flex">
          <li>
            1. Maximum video size is{" "}
            {ConfigDB.data.max_video_input_size_in_bytes / 1024 ** 2}MB.
          </li>
          <li className="mx-sm-3">
            2. You can upload upto {max_file_limit} files.
          </li>
          <li className="">3. Only videos are allowed.</li>
        </ul>
      </small>
    </div>
  );
};

export default SkillVideoUploader;
